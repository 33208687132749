import axios from 'axios';

const instance = axios.create({
  baseURL: window.npsi.restBase,
  headers: {
    common: {
      'X-WP-Nonce': window.npsi.nonce,
    },
  },
});

export default instance;
