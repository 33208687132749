import deepEqual from '../../utilities/deepEqual';
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../../utilities/axios';

const initialState = {
  data: {
    fields: {},
  },
  isLoading: false,
  error: null,
  fieldsAtLastRetrieval: {},
  isDirty: false,
  requiredFilesSet: false,
};

export const fetchData = createAsyncThunk(
  'orgInformation/fetchData',
  async () => {
    const response = await axios.get('/organization');
    const data = await response.data;
    const innerData = data.data;
    const { fields } = innerData;

    let actualFields = [...fields];

    innerData.fields = actualFields;
    const originalFields = [...innerData.fields];

    return { innerData, originalFields };
  },
);

export const fetchFields = createAsyncThunk(
  'orgInformation/fetchFields',
  async () => {
    const response = await axios.get('/fields');
    const data = await response.data;
    const innerData = data.data;

    innerData.fields = innerData.fields;
    const originalFields = { ...innerData.fields };

    return { innerData, originalFields };
  },
);

const orgInformationSlice = createSlice({
  name: 'orgInformation',
  initialState,
  reducers: {
    setData: (state, action) => {
      Object.keys(action.payload).length > 0 &&
        Object.keys(action.payload).forEach((key) => {
          state.data.fields[key] = action.payload[key];
        });
    },
    setDatum: (state, action) => {
      const currentState = current(state);

      const key = action.payload.key;
      const value = action.payload.value;
      let fields = currentState.data.fields;

      state.data.fields = fields.map((field) => {
        if (field.slug === key) {
          const newField = { ...field };
          newField.value = value;
          return newField;
        }
        return field;
      });

      state.isDirty = !deepEqual(
        state.fieldsAtLastRetrieval,
        state.data.fields,
      );
      const requiredFileFields = state.data.fields.filter((field) => {
        return (
          (field.type === 'featured_image' || field.type === 'image') &&
          field.required
        );
      });
      const anyRequiredFilesNotSet = requiredFileFields.some((field) => {
        return !field.value;
      });
      state.requiredFilesSet = !anyRequiredFilesNotSet;
    },
    checkDirtiness: (state) => {
      state.isDirty = !deepEqual(
        state.fieldsAtLastRetrieval,
        state.data.fields,
      );
      const requiredFileFields = state.data.fields.filter((field) => {
        return (
          (field.type === 'featured_image' || field.type === 'image') &&
          field.required
        );
      });
      const anyRequiredFilesNotSet = requiredFileFields.some((field) => {
        return !field.value;
      });
      state.requiredFilesSet = !anyRequiredFilesNotSet;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.innerData;
      state.fieldsAtLastRetrieval = action.payload.originalFields;
    });
    builder.addCase(fetchData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchFields.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchFields.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.innerData;
      state.fieldsAtLastRetrieval = action.payload.originalFields;
    });
    builder.addCase(fetchFields.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});
export const { setData, setDatum } = orgInformationSlice.actions;
export default orgInformationSlice.reducer;
