import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../button';
import { uneditableRoles } from '../../../constants/roles';
import deepEqual from '../../../utilities/deepEqual';
import { Tooltip } from 'react-tooltip';
import { setActiveTab, setLoading } from '../../../features/providers/generic';
import { toast } from 'react-toastify';
import axios from '../../../utilities/axios';
import { fetchData } from '../../../features/providers/orgInformationSlice';
const editUser = ({ user_id, cancel }) => {
  const postId = useSelector((state) => state.orgInformation.data.post_id);
  const user = useSelector((state) =>
    state.orgInformation.data.users.filter((user) => user.user_id === user_id),
  )?.pop();
  const dispatch = useDispatch();
  const onChangeSelect = (e) => {
    setRole(e.target.value);
  };

  const initialState = {
    email: user.user_email,
    role: user.role,
    name: user.display_name,
  };

  const [email, setEmail] = useState(user.user_email);
  const [role, setRole] = useState(user.role);
  const [name, setName] = useState(user.display_name);

  const currentState = {
    email,
    name,
    role,
  };

  const isDirty = !deepEqual(initialState, currentState);

  const inputStyle = {
    width: '100%',
    minWidth: '350px',
    padding: '10px',
    margin: '10px 0',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
  };
  const blackout = {
    position: 'fixed',
    zIndex: 1000,
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.5)',
  };
  const popupStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    backgroundColor: 'white',
    padding: '1rem',
    borderRadius: '0.5rem',
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));

      const request = await axios.put(`/organization/user/${user_id}/`, {
        name,
        email,
        role,

        postId,
      });

      dispatch(setActiveTab(1));
      const success = request?.data?.success;

      if (!success) {
        return toast("Error, that's kinda weird, I should never happen");
      }
      toast('Successfully added user');

      dispatch(fetchData());
    } catch (e) {
      const message = e?.response?.data?.data?.message || e.message;

      toast(message, { type: 'error' });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div style={blackout}>
      <div style={popupStyle}>
        <h1>Edit User</h1>
        <form onSubmit={onSubmit}>
          <p>
            <label>Name</label>
            <input
              style={inputStyle}
              required
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </p>
          <p>
            <label>Email</label>
            <input
              style={inputStyle}
              required
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </p>

          <p>
            <select
              onChange={onChangeSelect}
              style={inputStyle}
              required
              value={role}
            >
              <option value=""> -- select role -- </option>
              {window.npsi.roles
                .filter(({ slug, name, level }) => {
                  // Don't allow organization admins to add other organization admins
                  return (
                    parseInt(level) < 10 || !uneditableRoles.includes(slug)
                  );
                })
                .map(({ slug, label }) => {
                  return (
                    <option key={slug} value={slug}>
                      {label}
                    </option>
                  );
                })}
            </select>
          </p>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              gap: '1rem',
            }}
          >
            <input
              type="submit"
              disabled={!isDirty}
              className={`sw-no-unsaved-changes ml-0 inline-block transition duration-300 uppercase bg-red text-white button-solid hover:opacity-80`}
              value="submit"
              style={!isDirty ? { opacity: 0.3 } : {}}
            />
            <Button onClick={cancel}>Cancel</Button>
            {!isDirty && (
              <Tooltip anchorSelect=".sw-no-unsaved-changes" place="top">
                No unsaved changes
              </Tooltip>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default editUser;
