// providersSlice.js

import { createSlice } from '@reduxjs/toolkit';
export const ADD_CONTEXT = 'add';
export const UPDATE_CONTEXT = 'update';

const initialState = {
  loading: false,
  postId: null,
  overriddenLogo: null,
  activeTab: 0,
  inputContext: false,
};

export const generic = createSlice({
  name: 'generic',
  initialState,
  reducers: {
    setInputContextToAdd: (state, action) => {
      state.inputContext = ADD_CONTEXT;
    },
    setInputContextToUpdate: (state, action) => {
      state.inputContext = UPDATE_CONTEXT;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setPostId: (state, action) => {
      state.postId = action.payload;
    },
    setOverriddenLogo: (state, action) => {
      state.overriddenLogo = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setInitialLoadCompleted,
  setPostId,
  setOverriddenLogo,
  setActiveTab,
  setInputContextToAdd,
  setInputContextToUpdate,
} = generic.actions;

export default generic.reducer;
