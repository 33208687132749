import { useDispatch } from 'react-redux';
import './App.css';
import Main from './components/main';

import { ToastContainer } from 'react-toastify';
import { setInputContextToUpdate } from './features/providers/generic';

function ManageOrganization() {
  const dispatch = useDispatch();
  dispatch(setInputContextToUpdate());
  return (
    <>
      <Main />
      <ToastContainer position="bottom-right" />
    </>
  );
}

export default ManageOrganization;
