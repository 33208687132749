import { useSelector } from 'react-redux';
import AddUser from './users/addUser';
import UsersTable from './users/usersTable';

const Users = () => {
  const roleLevel = useSelector(
    (state) => state.orgInformation.data.role_level,
  );

  return (
    <>
      {roleLevel >= 3 && <AddUser />}
      <UsersTable />
    </>
  );
};

export default Users;
