import React from 'react';
import ReactDOM from 'react-dom/client';
import ManageOrganization from './ManageOrganization';
import store from './store';
import { Provider } from 'react-redux';
import AddOrganization from './AddOrganization';

if (document.getElementById('manage-organization')) {
  ReactDOM.createRoot(document.getElementById('manage-organization')).render(
    <Provider store={store}>
      <React.StrictMode>
        <ManageOrganization />
      </React.StrictMode>
    </Provider>,
  );
}

if (document.getElementById('add-organization')) {
  ReactDOM.createRoot(document.getElementById('add-organization')).render(
    <Provider store={store}>
      <React.StrictMode>
        <AddOrganization />
      </React.StrictMode>
    </Provider>,
  );
}
