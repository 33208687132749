import { configureStore } from '@reduxjs/toolkit';

import orgInformation from './features/providers/orgInformationSlice';
import generic from './features/providers/generic';
const store = configureStore({
  reducer: {
    orgInformation,
    generic,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}),
});

export default store;
