import { useState } from 'react';
import Org from './tabs/org';
import Users from './tabs/users';
import { useDispatch, useSelector } from 'react-redux';
import { Bars } from 'react-loader-spinner';
import { setActiveTab } from '../features/providers/generic';

const Tabs = () => {
  const dispatch = useDispatch();
  const loading = useSelector(
    (state) => state.orgInformation.isLoading || state.generic.loading,
  );
  const activeTab = useSelector((state) => state.generic.activeTab);
  const loaderStyle = {
    minHeight: '50vh',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100,
  };

  const tabs = [
    { key: 0, label: 'Information', children: <Org /> },
    { key: 1, label: 'Users', children: <Users /> },
  ];

  const activeContentStyle = {
    display: 'block',
  };

  const inactiveContentStyle = {
    display: 'none',
  };

  const sharedTabStyle = {
    padding: '0.25rem 1rem',
    borderRadius: '0.5rem 0.5rem 0 0 ',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: 0,
  };

  const activeTabStyle = {
    backgroundColor: '#011C50',
    color: '#fff',
    ...sharedTabStyle,
  };

  const inactiveTabStyle = {
    backgroundColor: '#fff',
    color: '#011C50',
    border: '1px solid #011C50',
    borderBottom: 'none',
    ...sharedTabStyle,
  };

  const parentTabStyle = {
    display: 'flex',
    listStyle: 'none',
    padding: '0',
    justifyContent: 'flex-start',
    gap: '1rem',
    width: '100%',
    borderBottom: '1px solid #011C50',
  };

  return (
    <>
      <ul style={parentTabStyle} role="tablist">
        {tabs.map(({ key, label }) => {
          const isActive = activeTab === key;

          return (
            <li
              aria-controls={`sw-tab-${key}`}
              role="tab"
              tabIndex={isActive ? 0 : -1}
              aria-selected={isActive}
              key={key}
              onClick={() => !isActive && dispatch(setActiveTab(key))}
              style={isActive ? activeTabStyle : inactiveTabStyle}
            >
              {label}
            </li>
          );
        })}
      </ul>
      <div>
        {loading && (
          <div style={loaderStyle}>
            <Bars
              height="80"
              width="80"
              radius="9"
              color="#011C50"
              ariaLabel="three-dots-loading"
              wrapperStyle
              wrapperClass
            />
          </div>
        )}
        {!loading &&
          tabs.map(({ key, children }) => {
            const isActive = activeTab === key;

            return (
              <div
                role="tabpanel"
                aria-labelledby={`sw-tab-${key}`}
                aria-selected={isActive}
                key={key}
                id={`sw-tab-${key}`}
                style={isActive ? activeContentStyle : inactiveContentStyle}
              >
                {children}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Tabs;
