import { useDispatch, useSelector } from 'react-redux';
import Input from '../inputs/input';
import axios from '../../utilities/axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';
import { deleteTransient, transientKey } from '../../utilities/cache';
import { setLoading } from '../../features/providers/generic';
import cleanText from '../../utilities/cleanText';
import Form from '../Form';

const Org = () => {
  const Fields = useSelector((state) => state.orgInformation.data.fields);

  const fieldsExist = Object.keys(Fields).length > 0;
  const postId = useSelector((state) => state.orgInformation.data.post_id);
  const isDirty = useSelector((state) => state.orgInformation.isDirty);

  const dispatch = useDispatch();

  const submit = async (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    try {
      dispatch(setLoading(true));

      const request = await axios.put('/organization', {
        fields: Fields,
        postId,
      });

      toast('Successfully updated');
    } catch (e) {
      toast('Error!' + e.message);
    } finally {
      deleteTransient(transientKey);
      dispatch(setLoading(false));
    }
  };

  const formElementsWrapStyle = {
    zIndex: 1,

    transition: 'opacity 0.3s ease-in-out',
    position: 'relative',
  };

  return (
    <>
      <form onSubmit={submit}>
        <div style={formElementsWrapStyle}>
          <Form fields={Fields} fieldsExist={fieldsExist} />
        </div>
      </form>
      {!isDirty && (
        <Tooltip anchorSelect=".sw-no-unsaved-changes" place="top">
          No unsaved changes
        </Tooltip>
      )}
    </>
  );
};

export default Org;
