import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../button';
import EditUser from './editUser';
import axios from '../../../utilities/axios';
import { uneditableRoles } from '../../../constants/roles';
import { setLoading } from '../../../features/providers/generic';
import { fetchData } from '../../../features/providers/orgInformationSlice';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const UsersTable = () => {
  const post_id = useSelector((state) => state.orgInformation.data.post_id);
  const roleLevel = useSelector(
    (state) => state.orgInformation.data.role_level,
  );

  const [editUser, setEditUser] = useState(null);
  const dispatch = useDispatch();

  const showDeleteConfirmation = (user_id, user_email, display_name) => {
    Swal.fire({
      title: 'Are you sure?',
      text: `Are you sure you wish to delete this user ${display_name} (${user_email}), this is permanent. You will have to create a brand new user with this email address if you wish for them to rejoin your organization.`,

      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonColor: '#803137',
      cancelButtonColor: '#021c50',
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked 'Yes'
        deleteUser(user_id);
      }
      // else, the modal was dismissed (either by clicking 'No' or outside the modal)
    });
  };

  const deleteUser = async (user_id) => {
    try {
      dispatch(setLoading(true));

      const request = await axios.delete(
        `/organization/user/${post_id}/${user_id}`,
      );
      const success = request?.data?.success;
      if (!success) {
        return toast("Error, that's kinda weird, I should never happen");
      }
      toast('Successfully deleted user');
      dispatch(fetchData());
    } catch (e) {
      const message = e?.response?.data?.data?.message || e.message;

      toast(message, { type: 'error' });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const users = useSelector((state) => state.orgInformation.data.users);

  if (!Array.isArray(users)) {
    return <p>No users...</p>;
  }
  const rows = users.map(
    ({
      user_id,
      role_label,
      role,
      display_name,
      requested_user,
      user_email,
    }) => {
      const current = user_id === requested_user;
      return (
        <tr key={user_id} className="user">
          <td>
            <div className="user__name">{display_name}</div>
          </td>
          <td>
            <div className="user__name">{user_email}</div>
          </td>
          <td>
            <div className="user__role">{role_label}</div>
          </td>
          <td>
            {uneditableRoles.includes(role) ? (
              <>Cannot edit</>
            ) : roleLevel >= 9 ? (
              <Button onClick={() => setEditUser(user_id)}>Edit</Button>
            ) : <>Cannot edit</>}
          </td>
          <td>
            {uneditableRoles.includes(role) ? (
              <>Cannot edit</>
            ) : roleLevel >= 9 ? (
              <Button
                onClick={() =>
                  showDeleteConfirmation(user_id, user_email, display_name)
                }
              >
                Delete
              </Button>
            ) : <>Cannot edit</>}
          </td>
        </tr>
      );
    },
  );

  return (
    <>
      {editUser && (
        <EditUser cancel={() => setEditUser(null)} user_id={editUser} />
      )}
      <table className="users">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th colSpan={2}>Actions</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </>
  );
};

export default UsersTable;
