import { useDispatch, useSelector } from 'react-redux';
import { setDatum } from '../../features/providers/orgInformationSlice';
import { ADD_CONTEXT, UPDATE_CONTEXT } from '../../features/providers/generic';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { useEffect, useState } from 'react';
import axios from '../../utilities/axios';

const Input = ({ data, onFocus, onBlur, requiredState }) => {
  const inputContext = useSelector((state) => state.generic.inputContext);
  const {
    slug,
    type,
    value,
    label,
    required,
    add_readonly,
    update_readonly,
    display,
    options,
    default_value,
    vertical,
  } = data;

  const borderStyle = {
    borderWidth: '3px',
    borderSTyle: 'solid',
    display: 'block',
    borderColor: requiredState ? '#803137' : '#011c50',
  };

  const ImageInput = ({
    type = 'image',
    required = false,
    requiredState = false,
  }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [placeholder, setPlaceholder] = useState(
      !!value && value.toString().substr(0, 5) === 'data:' ? value : null,
    );

    const checkFile = (file) => {
      let testFile = true; // for any file type
      if (type === 'image') {
        testFile = /image\/(jpeg|png|gif|svg\+xml)$/.test(file.type);
      }
      return testFile;
    };

    const getImage = async () => {
      if (isNaN(value) || !value) {
        return;
      }
      setLoading(true);
      try {
        const response = await axios.get(`/organization/logo/${value}`);
        setPlaceholder(response.data.data.image);
      } catch (e) {
        toast(e.message);
      } finally {
        setLoading(false);
      }
    };

    useEffect(() => {
      getImage();
    }, []);

    return (
      <div className={`sw-input`} style={{ marginBottom: '2rem' }}>
        <label htmlFor={slug}>{actualLabel}</label>
        {loading && <p>Loading...</p>}
        {!loading && (
          <>
            {placeholder && value ? (
              <p>
                <img
                  src={placeholder}
                  style={{ height: 'auto', width: 'auto', maxWidth: '100px' }}
                />
                <button
                  className="sw-no-unsaved-changes ml-0 inline-block transition duration-300 uppercase bg-red text-white button-solid hover:opacity-80"
                  onClick={() => {
                    dispatch(setDatum({ key: slug, value: null }));
                  }}
                >
                  Remove
                </button>
              </p>
            ) : (
              <div className="relative">
                <input
                  required={required}
                  disabled={readOnly}
                  style={borderStyle}
                  id={slug}
                  name={slug}
                  type="file"
                  onChange={(e) => {
                    setError(null);
                    if (e.target.files && e.target.files[0]) {
                      const file = e.target.files[0];

                      const testFile = checkFile(file);
                      if (testFile) {
                        const reader = new FileReader();
                        reader.onload = function (readEvent) {
                          const base64String = readEvent.target.result;
                          dispatch(
                            setDatum({
                              key: slug,
                              value: base64String,
                            }),
                          );
                        };

                        reader.readAsDataURL(file);
                      } else {
                        const message =
                          'Unsupported file type, use JPG, PNG or GIF';
                        setError(message);
                        toast(message);
                        e.target.value = '';
                        // Handle unsupported file type here
                      }
                    }
                  }}
                />
                {!value && (
                  <div className="absolute bottom-0 left-0">
                    <small className="text-red">Required</small>
                  </div>
                )}
                {error && (
                  <div
                    style={{
                      color: '#721c24',
                      backgroundColor: '#f8d7da',
                      margin: '1rem 0',
                      padding: '0.75rem 1.25rem',
                      border: '1px solid #fc56cb',
                      borderRadius: '0.25rem',
                      position: 'relative',
                    }}
                  >
                    {error}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  if (!display) {
    return <></>;
  }

  if (inputContext === UPDATE_CONTEXT && type === 'message') {
    return <></>;
  }

  const readOnly =
    inputContext === ADD_CONTEXT ? add_readonly : update_readonly;

  const actualRequired = !readOnly && required;

  const actualLabel = (
    <>
      {label} {actualRequired && !readOnly && <>*</>}
    </>
  );

  const dispatch = useDispatch();
  const onChange = (e) => {
    let value = e.target.value;
    const key = slug;

    if (type === 'url' && value && !value.startsWith('https://')) {
      value = 'https://' + value;
    }

    //Prevent bug of duplicate "https://" prefix
    if (value.startsWith('https://https:/')) {
      value = 'https://';
    }

    dispatch(setDatum({ key, value }));
  };

  const onChangeCheckbox = (e) => {
    const checkedItem = e.target.value;

    let updatedValue = [checkedItem];

    if (Array.isArray(value) && value.includes(checkedItem)) {
      updatedValue = [...value].filter((v) => v !== checkedItem);
    } else if (Array.isArray(value)) {
      updatedValue = [...value, checkedItem];
    }

    dispatch(setDatum({ key: slug, value: updatedValue }));
  };

  const wrapProps = slug ? { id: `input-${slug}` } : {};
  const tooltipWrap = slug ? { anchorSelect: `#input-${slug}` } : {};

  const content = () => {
    switch (type) {
      case 'checkbox_must_agree':
      case 'checkbox':
        return (
          <div
            style={
              type === 'checkbox_must_agree'
                ? {
                    padding: '1rem',
                    borderWidth: '3px',
                    borderStyle: 'solid',
                    display: 'inline-block',
                    borderColor: '#803137',
                  }
                : {}
            }
            className={`relative sw-input ${vertical ? 'no-flex' : ''}`}
          >
            <label>{actualLabel}</label>
            <div>
              {Object.keys(options).map((key) => (
                <div key={key}>
                  <input
                    readOnly={readOnly}
                    type="checkbox"
                    id={key}
                    name={slug}
                    value={key}
                    checked={value && value.includes(key)}
                    onChange={(e) => onChangeCheckbox(e, key, value, slug)}
                  />
                  <label htmlFor={key}>
                    {options[key]}{' '}
                    {type === 'checkbox_must_agree' && (
                      <small className="text-red inline-block ml-5 font-bold text-sm">
                        {!value.includes(key) && <>Required</>}
                      </small>
                    )}
                  </label>
                </div>
              ))}
            </div>
          </div>
        );

      case 'textarea':
        return (
          <>
            <div className={`sw-input ${requiredState ? 'border-red' : ''}`}>
              <label style={{ display: 'block' }} htmlFor={slug}>
                {actualLabel}
              </label>
              <div className="relative">
                <textarea
                  style={borderStyle}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  required={actualRequired}
                  readOnly={readOnly}
                  id={slug}
                  name={slug}
                  type={type}
                  value={value || default_value}
                  onChange={onChange}
                />
                {requiredState && (
                  <small className="text-red absolute bottom-[10px] left-0 z-10 text-sm">
                    Field is Required
                  </small>
                )}
              </div>
            </div>
          </>
        );
      case 'dropdown':
        return (
          <div className="sw-input">
            <label htmlFor={slug}>{actualLabel}</label>
            <div className="relative">
              <select
                disabled={readOnly}
                onChange={onChange}
                value={value || default_value}
                id={slug}
                name={slug}
                style={borderStyle}
              >
                <option value={''}> -- select -- </option>
                {Object.keys(options).map((k) => {
                  return (
                    <option key={k} value={k}>
                      {options[k]}
                    </option>
                  );
                })}
              </select>
              {requiredState && (
                <small className="text-red absolute bottom-[10px] left-0 z-10 text-sm">
                  Field is Required
                </small>
              )}
            </div>
          </div>
        );
        break;
      case 'title':
      case 'text':
      case 'url':
      case 'email':
        return (
          <div className="sw-input ">
            <label htmlFor={slug}>{actualLabel}</label>
            <div className="relative">
              <input
                style={borderStyle}
                onFocus={onFocus}
                onBlur={onBlur}
                required={actualRequired}
                readOnly={readOnly}
                id={slug}
                name={slug}
                type={type}
                value={value || default_value}
                placeholder={type === 'url' ? 'https://example.com' : ''}
                onChange={onChange}
              />
              {requiredState && (
                <small className="text-red absolute bottom-[10px] left-0 z-10 text-sm">
                  Field is Required
                </small>
              )}
            </div>
          </div>
        );
        break;
      case 'featured_image':
      case 'image':
        return (
          <ImageInput requiredState={requiredState} required={actualRequired} />
        );
        break;
      // Add other cases as needed
      case 'message':
        return <strong className="block my-10">{actualLabel}</strong>;
      default:
        return <p>NOTHING FOR {type}</p>;
    }
  };

  return (
    <>
      {readOnly && (
        <Tooltip {...tooltipWrap}>This field cannot be updated</Tooltip>
      )}

      <div {...wrapProps}>{content()}</div>
    </>
  );
};
export default Input;
