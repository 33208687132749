const Button = ({ onClick, color = 'blue', children, ...props }) => {
  return (
    <button
      {...props}
      className={`ml-0 inline-block transition duration-300 uppercase bg-${color} text-white button-solid hover:opacity-80`}
      onClick={() => onClick()}
    >
      {children}
    </button>
  );
};

export default Button;
