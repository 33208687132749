import './App.css';

import { ToastContainer } from 'react-toastify';
import Signup from './components/signup';
import { useDispatch } from 'react-redux';
import { fetchFields } from './features/providers/orgInformationSlice';
import { useEffect } from 'react';
import { setInputContextToAdd } from './features/providers/generic';

function AddOrganization() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFields());
  }, [dispatch]);

  dispatch(setInputContextToAdd());
  return (
    <>
      <Signup />
      <ToastContainer position="bottom-right" />
    </>
  );
}

export default AddOrganization;
