import { useEffect } from 'react';
import Tabs from './tabs';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../features/providers/orgInformationSlice';

const Main = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);
  const error = useSelector((state) => state.orgInformation.error);

  return (
    <>
      <Tabs />
    </>
  );
};

export default Main;
