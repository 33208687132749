import {useState} from 'react';
import Button from '../../button';
import validateEmail from '../../../utilities/validateEmail';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {setLoading} from '../../../features/providers/generic';
import axios from '../../../utilities/axios';
import {fetchData} from '../../../features/providers/orgInformationSlice';
import {uneditableRoles} from '../../../constants/roles';

const inputStyle = {
  width: '100%',
  padding: '10px',
  margin: '10px 0',
  border: '1px solid #ccc',
  borderRadius: '4px',
  boxSizing: 'border-box',
};

const addUser = () => {
  const postId = useSelector((state) => state.orgInformation.data.post_id);


  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [slideDown, setSlideDown] = useState(false);
  const [inValidEmail, setInValidEmail] = useState(false);
  const onSubmit = async (e) => {
    setInValidEmail(false);
    e.preventDefault();
    if (!validateEmail(email)) {
      return setInValidEmail(true);
    }

    try {
      dispatch(setLoading(true));

      const request = await axios.post('/organization/user', {
        name,
        email,
        role,
        postId,
      });
      const success = request?.data?.success;
      if (!success) {
        return toast("Error, that's kinda weird, I should never happen");
      }
      toast('Successfully added user');
      dispatch(fetchData());
    } catch (e) {
      const message = e?.response?.data?.data?.message || e.message;

      toast(message, {type: 'error'});
    } finally {
      dispatch(setLoading(false));
    }
  };

  const [role, setRole] = useState('');
  const onChangeSelect = (e) => {
    setRole(e.target.value);
  };

  return (
    <>
      <Button color="red" onClick={() => setSlideDown(!slideDown)}>
        {!slideDown && <>Add User</>}
        {slideDown && <>← Go Back</>}
      </Button>
      <div
        className="add-user"
        style={{display: slideDown ? 'block' : 'none'}}
      >
        {inValidEmail && <p style={{color: 'red'}}>Invalid Email</p>}

        <form onSubmit={onSubmit}>
          <input
            required
            style={inputStyle}
            type="text"
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            required
            style={inputStyle}
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <select onChange={onChangeSelect} style={inputStyle} required>
            <option value=""> -- select role --</option>
            {window.npsi.roles
              .filter(({slug, name, level}) => {
                // Don't allow organization admins to add other organization admins
                return parseInt(level) < 10 || !uneditableRoles.includes(slug);
              })
              .map(({slug, label}) => {
                return (
                  <option key={slug} value={slug}>
                    {label}
                  </option>
                );
              })}
          </select>
          <input
            type="submit"
            value="Submit"
            className="ml-0 inline-block transition duration-300 uppercase bg-blue text-white button-solid hover:opacity-80"
          />
        </form>
      </div>
    </>
  );
};

export default addUser;
