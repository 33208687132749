import { useSelector } from 'react-redux';
import Input from './inputs/input';
import { useState } from 'react';
import React from 'react';
const Form = ({ fields, fieldsExist }) => {
  const [gainedFocused, setGainedFocused] = useState(['logo_id']);
  const [lostFocused, setLostFocused] = useState(['logo_id']);

  const isDirty = useSelector((state) => state.orgInformation.isDirty);
  const requiredFilesSet = useSelector(
    (state) => state.orgInformation.requiredFilesSet,
  );

  const [missingRequiredFieldsSlugs, missingRequiredFieldsLabels] = useSelector(
    (state) => {
      const missingFields =
        state?.orgInformation?.data?.fields &&
        Array.isArray(state.orgInformation.data.fields) &&
        state.orgInformation.data.fields.filter(
          (field) => field.required && !field.value,
        );

      const missingFieldsLabels =
        missingFields &&
        Array.isArray(missingFields) &&
        missingFields.map((field, i) => (
          <React.Fragment key={i}>{field.label}</React.Fragment>
        ));
      if (!state.requiredFilesSet && Array.isArray(missingFieldsLabels)) {
        missingFieldsLabels.unshift('Logo');
      }
      const missingFieldsSlugs =
        missingFields &&
        Array.isArray(missingFields) &&
        missingFields.map((field) => field.slug);
      if (
        state?.orgInformation?.data?.fields?.logo_id &&
        !state.requiredFilesSet &&
        Array.isArray(missingFieldsSlugs)
      ) {
        missingFieldsSlugs.unshift('logo_id');
      }
      return [missingFieldsSlugs, missingFieldsLabels];
    },
  );

  const joinedIsDirty =
    isDirty && requiredFilesSet && missingRequiredFieldsSlugs.length === 0;
  return (
    <>
      {Object.keys(fields).map((key) => (
        <React.Fragment key={key}>
          <Input
            onFocus={() =>
              setGainedFocused((prev) => [
                ...new Set([...prev, fields[key].slug]),
              ])
            }
            onBlur={() =>
              setLostFocused((prev) => [
                ...new Set([...prev, fields[key].slug]),
              ])
            }
            data={fields[key]}
            key={key}
            requiredState={
              gainedFocused.includes(fields[key].slug) &&
              lostFocused.includes(fields[key].slug) &&
              missingRequiredFieldsSlugs.includes(fields[key].slug)
            }
          />
        </React.Fragment>
      ))}
      {fieldsExist && (
        <>
          <input
            type="submit"
            disabled={!joinedIsDirty}
            className={`sw-no-unsaved-changes ml-0 inline-block transition duration-300 uppercase bg-red text-white button-solid hover:opacity-80`}
            value="submit"
            style={!joinedIsDirty ? { opacity: 0.3 } : {}}
          />
          {!joinedIsDirty && (
            <small className="text-red inline-block ml-5">
              Please fill out all required fields above
            </small>
          )}
        </>
      )}
    </>
  );
};

export default Form;
