// Function to set a transient with an expiration time (in milliseconds)
function setTransient(key, value, duration) {
  const expireAt = new Date().getTime() + duration;
  const transient = { value, expireAt };
  localStorage.setItem(key, JSON.stringify(transient));
}

// Function to get a transient. Returns null if the transient doesn't exist or is expired
function getTransient(key) {
  const transientJson = localStorage.getItem(key);
  if (transientJson) {
    const transient = JSON.parse(transientJson);
    if (new Date().getTime() < transient.expireAt) {
      return transient.value;
    } else {
      // Remove expired transient
      localStorage.removeItem(key);
    }
  }
  return null;
}

function deleteTransient(key) {
  localStorage.removeItem(key);
}

const transientKey = 'sw-data-transient-key';

export { setTransient, getTransient, deleteTransient, transientKey };
